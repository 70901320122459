import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard, HomePageLayoutComponent } from '@londonhydro/ux-lib';
import { AuditFormComponent } from './audit-form/audit-form.component';
import { BlockAccountFormComponent } from './block-account-form/block-account-form.component';
import { BlockAccountComponent } from './block-account/block-account.component';
import { ChangeLoginEmailFormComponent } from './change-login-email-form/change-login-email-form.component';
import { ChangeLoginEmailComponent } from './change-login-email/change-login-email.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CsrHomeComponent } from './csr-home/csr-home.component';
import { CsrLandingPageComponent } from './csr-landing-page/csr-landing-page.component';
import { CustLookupChangeEmailFormComponent } from './cust-lookup-change-email-form/cust-lookup-change-email-form.component';
import { CustomerLookupFormComponent } from './customer-lookup-form/customer-lookup-form.component';
import { CustomerRegistrationFormComponent } from './customer-registration-form/customer-registration-form.component';
import { CustomerRegistrationComponent } from './customer-registration/customer-registration.component';
import { CustomerSearchDetailComponent } from './customer-search-detail/customer-search-detail.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DownloadReportsComponent } from './download-reports/download-reports.component';
import { EditProfileFormComponent } from './edit-profile-form/edit-profile-form.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { MyProfileOptionsComponent } from './my-profile-options/my-profile-options.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { PendingCisUpdatesComponent } from './pending-cis-updates/pending-cis-updates.component';
import { PendingRegistrationComponent } from './pending-registration/pending-registration.component';
import { RegistrationCheckSeacrhComponent } from './registration-check-seacrh/registration-check-seacrh.component';
import { ServiceLocationComponent } from './service-location/service-location.component';
import { ServiceRequestDashboardComponent } from './service-request-dashboard/service-request-dashboard.component';
import { ServiceRequestComponent } from './service-request/service-request.component';
import { UnblockAccountFormComponent } from './unblock-account-form/unblock-account-form.component';
import { UnblockAccountComponent } from './unblock-account/unblock-account.component';
import { UserDetailViewComponent } from './user-detail-view/user-detail-view.component';
import { UserProfileViewComponent } from './user-profile-view/user-profile-view.component';
import { UserSearchFormComponent } from './user-search-form/user-search-form.component';
import { ServiceRequestCisDashboardComponent } from './service-request-cis-dashboard/service-request-cis-dashboard.component';

export const CSR_ROUTES: Routes = [{
  path: '',
  canActivate: [AuthenticationGuard],
  component: HomePageLayoutComponent,
  children: [{
    path: 'csrapp',
    component: CsrHomeComponent,
    canActivate: [AuthenticationGuard],
    data: {
      role: 'CSR'
    },
    children: [
      { path: 'home', component: CsrLandingPageComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'service-requests', component: ServiceRequestDashboardComponent },
      { path: 'service-requests/:selectedTab', component: ServiceRequestDashboardComponent },
      { path: 'service-requests-cis', component: ServiceRequestCisDashboardComponent },
      { path: 'service-requests-cis/:selectedTab', component: ServiceRequestCisDashboardComponent },
      { path: 'manage-forms', component: ServiceRequestComponent },
      { path: 'user-search', component: UserSearchFormComponent },
      { path: 'customer-search', component: CustomerLookupFormComponent },
      { path: 'user-detail-view', component: UserDetailViewComponent },
      { path: 'cust-search-details', component: CustomerSearchDetailComponent },
      { path: 'customer-registration', component: CustomerRegistrationComponent },
      { path: 'customer-registration-form', component: CustomerRegistrationFormComponent },
      { path: 'my-profile', component: MyProfileComponent },
      { path: 'user-profile-view', component: UserProfileViewComponent },
      { path: 'my-profile-options', component: MyProfileOptionsComponent },
      { path: 'edit-profile', component: EditProfileComponent },
      { path: 'edit-profile-form', component: EditProfileFormComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'change-password-form', component: ChangePasswordFormComponent },
      { path: 'cust-lookup-change-email-form', component: CustLookupChangeEmailFormComponent },
      { path: 'change-login-email', component: ChangeLoginEmailComponent },
      { path: 'change-login-email-form', component: ChangeLoginEmailFormComponent },
      { path: 'block-account', component: BlockAccountComponent },
      { path: 'block-account-form', component: BlockAccountFormComponent },
      { path: 'unblock-account', component: UnblockAccountComponent },
      { path: 'unblock-account-form', component: UnblockAccountFormComponent },
      { path: 'service-plan', component: ServiceLocationComponent },
      { path: 'pending-registration', component: PendingRegistrationComponent },
      { path: 'pending-cis-updates', component: PendingCisUpdatesComponent },
      { path: 'registration-check-search', component: RegistrationCheckSeacrhComponent },
      { path: 'download-reports', component: DownloadReportsComponent },
      { path: 'audits', component: AuditFormComponent }
    ]
  }
  ]
}
];


@NgModule({
  exports: [RouterModule]
})
export class CsrRoutingModule { }
